import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import AdminButton from './Admin/AdminButton';

const Navbar = ({ isLoggedIn }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      const storedUserId = localStorage.getItem('user_id');
      setIsAdmin(storedUserId === '1');
    } else {
      setIsAdmin(false);
    }
  }, [isLoggedIn]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo/Title */}
          <div className="flex-shrink-0">
            <NavLink to="/" className="text-2xl font-bold no-underline hover:text-yellow-400">
              BINO
            </NavLink>
          </div>

          {/* Hamburger button (mobile) */}
          <div className="flex md:hidden">
            <button
              onClick={toggleMobileMenu}
              type="button"
              className="text-gray-200 hover:text-white focus:outline-none focus:text-white"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMobileMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5h16v2H4zm0 12h16v2H4z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5h16v2H4zm0 6h16v2H4zm0 6h16v2H4z"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Menu (hidden on mobile by default, shown on md+ screens) */}
          <div className="hidden md:flex md:items-center space-x-6">
            <NavLink to="/" className="hover:bg-gray-700 px-3 py-2 rounded-lg">
              Αρχική
            </NavLink>
            <NavLink to="/strategy" className="hover:bg-gray-700 px-3 py-2 rounded-lg">
              Στρατηγικές
            </NavLink>
            <NavLink to="/about" className="hover:bg-gray-700 px-3 py-2 rounded-lg">
              Σχετικά
            </NavLink>
            {isAdmin && <AdminButton />}
            {!isLoggedIn && <span className="text-blue-300 px-3 py-2">Logging in...</span>}
          </div>
        </div>
      </div>

      {/* Mobile menu dropdown */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-gray-700">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <NavLink
              to="/"
              className="block px-3 py-2 rounded-md text-white hover:bg-gray-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Αρχική
            </NavLink>
            <NavLink
              to="/strategy"
              className="block px-3 py-2 rounded-md text-white hover:bg-gray-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Στρατηγικές
            </NavLink>
            <NavLink
              to="/about"
              className="block px-3 py-2 rounded-md text-white hover:bg-gray-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Σχετικά
            </NavLink>
            {isAdmin && (
              <div className="px-3 py-2">
                <AdminButton onClick={() => setIsMobileMenuOpen(false)} />
              </div>
            )}
            {!isLoggedIn && <span className="text-blue-300 px-3 py-2 block">Logging in...</span>}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
