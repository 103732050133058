// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Strategy from './pages/Strategy';
import About from './pages/About';
import Admin from './components/Admin/Admin';
import { initializeGA } from './services/analytics';
import { checkUserLoggedIn, fingerprintLogin } from './services/api';
import { Helmet } from 'react-helmet';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize Google Analytics and check login status
  useEffect(() => {
    initializeGA('G-MY5TMKK0QR');
    async function initLoginStatus() {
      try {
        const loggedIn = await checkUserLoggedIn();
        setIsLoggedIn(loggedIn);
      } catch (err) {
        console.error('Error checking login status:', err);
        setIsLoggedIn(false);
      } finally {
        setIsLoading(false);
      }
    }
    initLoginStatus();
  }, []);

  // If not logged in, automatically attempt fingerprint login once
  useEffect(() => {
    if (!isLoggedIn && !loginAttempted) {
      setLoginAttempted(true);
      (async () => {
        try {
          const data = await fingerprintLogin();
          if (data?.user_id) {
            localStorage.setItem('user_id', data.user_id);
            setIsLoggedIn(true);
          } else {
            console.error('Fingerprint login returned no user_id. Response:', data);
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Fingerprint login failed:', error);
          setIsLoggedIn(false);
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // If you intend this effect to run only once on mount, you can disable ESLint's dependency warning:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, loginAttempted]);

  // While fingerprint login is in progress, show a loading screen.
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl">Loading, please wait...</p>
      </div>
    );
  }

  return (
    <Router>
      <Helmet>
        <title>Προβλέψεις Αριθμών KINO Από Τον BINO AI</title>
        <meta name="description" content="Ανακαλύψτε τις πιο ακριβείς προβλέψεις αριθμών KINO από τον BINO AI." />
        <link rel="canonical" href="https://www.bino.gr/" />
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <Navbar isLoggedIn={isLoggedIn} />
        <div className="flex-grow">
          <Routes>
            {/* No redirect to a /login route; all routes are rendered regardless */}
            <Route path="/" element={<Home />} />
            <Route path="/strategy" element={<Strategy />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
