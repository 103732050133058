import React, { useState, useRef } from 'react';
import { matchNumbers } from '../../services/api';
import { Box, Button, TextField, Typography, Card, CardContent, Grid } from '@mui/material';
import { formatToAthensTime } from '../../utils/formatDate';

const MatchNumbers = () => {
  const [count, setCount] = useState(12); // Default to 12 numbers
  const [numbers, setNumbers] = useState(Array(12).fill(''));
  const [matches, setMatches] = useState([]);
  const [error, setError] = useState('');
  const inputRefs = useRef([]);

  const handleCountChange = (e) => {
    const newCount = parseInt(e.target.value, 10);
    setCount(newCount);
    setNumbers(Array(newCount).fill(''));
    setMatches([]);
    setError('');
  };

  const handleInputChange = (index, value) => {
    const newNumbers = [...numbers];
    newNumbers[index] = value.replace(/\D/g, ''); // Allow only digits
    setNumbers(newNumbers);

    if (value.length === 2 && index < count - 1) {
      inputRefs.current[index + 1]?.focus(); // Auto-focus next input
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedNumbers = e.clipboardData
      .getData('Text')
      .split(/[\s,]+/)
      .filter((num) => /^\d{1,2}$/.test(num))
      .slice(0, count);

    const newNumbers = Array(count).fill('');
    pastedNumbers.forEach((num, index) => {
      newNumbers[index] = num;
    });
    setNumbers(newNumbers);
  };

  const handleSubmit = async () => {
    setError('');
    setMatches([]);
    const validNumbers = numbers.filter((num) => num !== '');
    if (validNumbers.length !== count) {
      setError(`Please enter exactly ${count} numbers.`);
      return;
    }

    try {
      const response = await matchNumbers(validNumbers);
      if (response.success) {
        setMatches(response.matches);
      } else {
        setError(response.error || 'Failed to find matches.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Enter Numbers to Find Matches
      </Typography>

      {/* Dropdown to Select Number Count */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography>Select number of inputs:</Typography>
        <select value={count} onChange={handleCountChange} style={{ padding: '5px', fontSize: '16px' }}>
          <option value={5}>5 Numbers</option>
          <option value={7}>7 Numbers</option>
          <option value={12}>12 Numbers</option>
        </select>
      </Box>

      {/* Input Fields */}
      <Box onPaste={handlePaste} sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {numbers.map((num, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            value={num}
            onChange={(e) => handleInputChange(index, e.target.value)}
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 2 }}
            placeholder={`#${index + 1}`}
            sx={{ width: '60px', textAlign: 'center' }}
          />
        ))}
      </Box>

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ marginTop: 2, display: 'block' }}
      >
        Find Matches
      </Button>

      {/* Error Message */}
      {error && (
        <Typography color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {/* Results Display */}
      {matches.length > 0 && (
        <Box sx={{ marginTop: 3 }}>
          <Typography variant="h5" gutterBottom>
            Matching Results:
          </Typography>
          <Grid container spacing={2}>
            {matches.map((match, idx) => (
              <Grid item xs={12} sm={6} key={idx}>
                <Card sx={{ backgroundColor: '#f9f9f9', boxShadow: 2 }}>
                  <CardContent>
                    <Typography variant="body1">
                      <strong>Draw ID:</strong> {match.drawId}
                    </Typography>
                    <Typography variant="body1">
                    <strong>Draw Time:</strong> {formatToAthensTime(match.drawTime)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Winning Numbers:</strong> {match.winningNumbers}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'green' }}>
                      <strong>Matched Numbers:</strong> {match.matches.join(', ') || 'No matches'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Match Count:</strong> {match.matchCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default MatchNumbers;
