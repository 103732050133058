// DonationModal.js
import React from 'react';

const DonationModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      {/* 
        Use responsive widths and `max-h-screen` to allow scrolling if needed.
        `overflow-auto` ensures content is scrollable on small screens.
      */}
      <div className="bg-white rounded-lg p-6 shadow-lg w-full sm:w-11/12 md:w-8/12 lg:w-1/2 xl:w-1/3 max-h-screen overflow-auto overflow-x-hidden">

        <h2 className="text-2xl font-bold text-blue-800 mb-4 text-center">
          Υποστηρίξτε την Κοινότητά μας με μια Δωρεά!
        </h2>
        <p className="text-gray-700 mb-4">
          Καλωσορίσατε στη σελίδα δωρεών! Είμαι ένας δημιουργός που έχει πάθος
          με την τεχνολογία και τη δημιουργία εργαλείων που εμπνέουν, διασκεδάζουν
          και βοηθούν τους χρήστες. Η εφαρμογή μου είναι αποτέλεσμα ατελείωτων ωρών
          εργασίας, με στόχο να παρέχω τις πιο ακριβείς και χρήσιμες προβλέψεις.
        </p>
        <p className="text-gray-700 mb-4">
          Γιατί να κάνετε μια δωρεά; Η δωρεά σας:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Υποστηρίζει τη συνέχιση του έργου μου.</li>
          <li>Μου δίνει τη δυνατότητα να διατηρώ την υπηρεσία δωρεάν.</li>
          <li>Εμπνέει την καινοτομία και τις βελτιώσεις.</li>
        </ul>
        <p className="text-gray-700 mb-4">
          Ακόμη και ένα μικρό ποσό κάνει τεράστια διαφορά. Σας ευχαριστώ για τη στήριξή σας!
        </p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
          >
            Κλείσιμο
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonationModal;
