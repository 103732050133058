import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const REACT_APP_CONTACT_BASE_URL = process.env.REACT_APP_CONTACT_BASE_URL;
const REACT_APP_REVIEWS_BASE_URL = process.env.REACT_APP_REVIEWS_BASE_URL;
const REACT_APP_USERS_BASE_URL = process.env.REACT_APP_USERS_BASE_URL;
const REACT_APP_LOTTERY_NUMBERS_BASE_URL = process.env.REACT_APP_LOTTERY_NUMBERS_BASE_URL;
const REACT_APP_PREDICTIONS_BASE_URL = process.env.REACT_APP_PREDICTIONS_BASE_URL;
const REACT_APP_STRATEGY_PREDICTIONS_BASE_URL = process.env.REACT_APP_STRATEGY_PREDICTIONS_BASE_URL;
const REACT_APP_ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
// const ADMIN_USER_ID = process.env.REACT_APP_ADMIN_USER_ID;


const FINGERPRINT_LOGIN_URL = `${REACT_APP_USERS_BASE_URL}/fingerprint_login`;

axios.defaults.withCredentials = true;

export const getUserId = () => localStorage.getItem('user_id');

export const getUserEmail = () => localStorage.getItem('user_email');


async function getFingerprintFromDevice() {
  let fingerprint = localStorage.getItem('deviceFingerprint');
  if (!fingerprint) {
    fingerprint = uuidv4();
    localStorage.setItem('deviceFingerprint', fingerprint);
  }
  return fingerprint;
}

export async function fingerprintLogin() {
  try {
    const fingerprint = await getFingerprintFromDevice();
    console.log('Attempting fingerprint login with:', fingerprint);

    const response = await axios.post(
      FINGERPRINT_LOGIN_URL, 
      { fingerprint },
      { withCredentials: true }
    );

    console.log('Fingerprint login success:', response.data);

    if (response.data?.user_id) {
      localStorage.setItem('user_id', response.data.user_id);
    }

    return response.data; 
  } catch (error) {
    console.error('Fingerprint login error:', error.response?.data || error.message);
    throw error;
  }
}

export const checkUserLoggedIn = async () => {
  try {
    const response = await axios.get(`${REACT_APP_USERS_BASE_URL}/check_logged_in`, {
      withCredentials: true,
    });
    return response.data.logged_in;  
  } catch (error) {
    console.error('Error checking user login status:', error);
    return false;
  }
};

export const logoutUser = async () => {
  const user_id = getUserId();

  if (!user_id) {
    console.warn('No user_id found in localStorage. User may already be logged out.');
    return;  
  }

  try {
    const response = await axios.post(
      `${REACT_APP_USERS_BASE_URL}/logout`,
      { user_id },  
      { withCredentials: true }
    );

    localStorage.removeItem('user_id');
    return response.data;
  } catch (error) {
    console.error('Error logging out user:', error);
    localStorage.removeItem('user_id');
    throw error;
  }
};


export const fetchLast20WinningNumbers = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_LOTTERY_NUMBERS_BASE_URL}/last_20_winning_numbers?_=${new Date().getTime()}`, // Prevent caching
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching last 20 winning numbers:', error);
    throw error;
  }
};

export const fetchPenultimateWinningNumbers = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_LOTTERY_NUMBERS_BASE_URL}/penultimate_winning_numbers?_=${new Date().getTime()}`, // Prevent caching
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching penultimate winning numbers:', error);
    throw error;
  }
};


export const fetchPredictions = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error('User ID is missing. Please log in.');

    const response = await axios.post(
      `${REACT_APP_PREDICTIONS_BASE_URL}/predict`,
      { user_id: userId },  
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching predictions:', error);
    throw error;
  }
};

export const fetchComparisonResult = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error('User ID is missing. Please log in.');

    const response = await axios.get(`${REACT_APP_PREDICTIONS_BASE_URL}/compare_predictions`, {
      params: { user_id: userId },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching comparison result:', error);
    throw error;
  }
};

export const fetchPenultimatePrediction = async () => {
  const user_id = getUserId();
  try {
    const response = await axios.get(`${REACT_APP_PREDICTIONS_BASE_URL}/penultimate_prediction`, {
      headers: { 'user_id': user_id },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching penultimate prediction:', error);
    throw error;
  }
};

export const saveComparisonResult = async (predictions, winningNumbers) => {
  try {
    for (const prediction of predictions) {
      const response = await axios.post(
        `${REACT_APP_PREDICTIONS_BASE_URL}/save_comparison_result`,
        {
          prediction_id: prediction.prediction_id,
          predicted_numbers: prediction.predicted_numbers,
          drawn_numbers: winningNumbers,
          user_id: prediction.user_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      console.log('Comparison result saved:', response.data);
    }
  } catch (error) {
    console.error('Error saving comparison result:', error.response?.data || error.message);
    throw error;
  }
};

export const calculateStrategy = async (userId, strategyType) => {
  try {
    const response = await axios.post(
      `${REACT_APP_STRATEGY_PREDICTIONS_BASE_URL}/strategy`,
      {
        user_id: userId,
        strategy_type: strategyType
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    return response.data;   
  } catch (error) {
    console.error('Error calculating strategy:', error);
    throw error;  
  }
};


export const fetchStrategyComparisonResult = async (userId, strategyType) => {
  try {
    const response = await axios.get(`${REACT_APP_STRATEGY_PREDICTIONS_BASE_URL}/compare_strategy_predictions`, {
      params: { user_id: userId, strategy_type: strategyType },
      withCredentials: true,
    });
    console.debug(`Received request for user_id=${userId} and strategy_type=${strategyType}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching strategy comparison result:', error);
    throw error;
  }
};

export const submitContactMessage = async (userId, message) => {
  try {
    const response = await axios.post(
      `${REACT_APP_CONTACT_BASE_URL}/contact`,
      { user_id: userId, message: message },
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error submitting contact message:', error);
    throw error;
  }
};

export const submitReview = async (userId, rating, comment) => {
  try {
    const response = await axios.post(
      `${REACT_APP_REVIEWS_BASE_URL}/reviews`,
      { user_id: userId, rating: rating, comment: comment },
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error submitting review:', error);
    throw error;
  }
};

// Admin endpoints
export const fetchAllUsers = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_ADMIN_BASE_URL}/users`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const fetchAllReviews = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_ADMIN_BASE_URL}/all_reviews`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const filterUsersByTargetGroupAndHits = async (targetGroup, matchCount) => {
  try {
    const response = await axios.post(
      `${REACT_APP_ADMIN_BASE_URL}/filter_users`,
      { target_group: targetGroup, match_count: matchCount },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error filtering users:', error.response?.data || error);
    throw error;
  }
};

export const matchNumbers = async (numbers) => {
  try {
    const response = await axios.post(
      `${REACT_APP_ADMIN_BASE_URL}/match_numbers`,
      { numbers },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error('Error matching numbers:', error);
    throw error;
  }
};

export const fetchBonusCoefficientsStatistics = async (period = 'all') => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_BASE_URL}/comparison_statistics_by_category?period=${period}&category=bonus_coefficients`,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bonus coefficients statistics:", error);
    return { success: false, error: "Failed to fetch bonus coefficients data" };
  }
};




