import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ReviewsTab from './ReviewsTab';
import FilteredUsers from './FilteredUsers';
import GroupedComparisonChart from './GroupedComparisonChart';
import CoefficientsThanOneChart from './CoefficientsThanOneChart';
import BonusCoefficientsChart from './BonusCoefficientsChart';
import Users from './Users';
import MatchNumbers from './MatchNumbers';


const Admin = () => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Tabs value={currentTab} onChange={handleChange} aria-label="admin dashboard tabs">
        <Tab label="Κριτικές" />
        <Tab label="Συντελεστές Επιτυχιών" />
        <Tab label="παρά ενός Επιτυχιών" />
        <Tab label="Συντελεστές μπόνους" />
        <Tab label="Αναζήτηση επιτυχιών" />
        <Tab label="Μελλοντικές Επιτυχίες" />
        <Tab label="Χρήστες" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {currentTab === 0 && <ReviewsTab />}
        {currentTab === 1 && <GroupedComparisonChart />}
        {currentTab === 2 && <CoefficientsThanOneChart />}
        {currentTab === 3 && <BonusCoefficientsChart />}
        {currentTab === 4 && <FilteredUsers />}
        {currentTab === 5 && <MatchNumbers />}
        {currentTab === 6 && <Users />}
      </Box>
    </Box>
  );
};

export default Admin;
