import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Button, CircularProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

const targetGroups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const colors = {
  1: "#8884d8",
  2: "#82ca9d",
  3: "#ffc658",
  4: "#ff7f50",
  5: "#8dd1e1",
  6: "#a4de6c",
  7: "#d0ed57",
  8: "#ffc0cb",
  9: "#87cefa",
  10: "#dda0dd",
  11: "#f4a460",
  12: "#90ee90",
};

const CoefficientsThanOneChart = () => {
  const [period, setPeriod] = useState('daily');
  const [data, setData] = useState([]);
  const [overallAdditions, setOverallAdditions] = useState(0);
  const [loading, setLoading] = useState(false);
  const category = "coefficients_than_one";

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_BASE_URL}/comparison_statistics_by_category?period=${period}&category=${category}`,
        { withCredentials: true }
      );
      console.log("Fetched coefficients_than_one data:", response.data);
      if (response.data.success) {
        setData(response.data.data);
        setOverallAdditions(response.data.overall_additions);
      } else {
        alert(`Error: ${response.data.error}`);
      }
    } catch (error) {
      console.error("Error fetching coefficients_than_one data:", error);
      alert("Error fetching coefficients_than_one data.");
    } finally {
      setLoading(false);
    }
  }, [period, category]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tickFormatter = (value) => {
    if (period === 'daily') {
      const hourStr = value.toString().padStart(2, '0');
      const nextHour = value === 23 ? '00' : (parseInt(value, 10) + 1).toString().padStart(2, '0');
      return `${hourStr}:00 - ${nextHour}:00`;
    } else if (period === 'monthly') {
      return `Day ${value}`;
    } else if (period === 'weekly') {
      return value;
    } else {
      return value;
    }
  };

  const handleExport = () => {
    const node = document.getElementById('chart-container');
    domtoimage.toBlob(node)
      .then((blob) => {
        saveAs(blob, 'coefficients_than_one_chart.png');
      })
      .catch((error) => {
        console.error("Error exporting chart:", error);
        alert("Error exporting chart.");
      });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Διάγραμμα Επιτυχιών - Απόδοση Προβλέψεων
      </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Σύνολο πονταρίσματος: {overallAdditions}
      </Typography>
      <RadioGroup row value={period} onChange={(e) => setPeriod(e.target.value)}>
        <FormControlLabel value="daily" control={<Radio />} label="Ωριαία" />
        <FormControlLabel value="weekly" control={<Radio />} label="Ημερήσια" />
        <FormControlLabel value="monthly" control={<Radio />} label="Εβδομαδιαία" />
        <FormControlLabel value="all" control={<Radio />} label="Όλα τα αποτελέσματα" />
      </RadioGroup>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button variant="contained" onClick={fetchData}>
          Ανανέωση δεδομένων
        </Button>
        <Button variant="outlined" onClick={handleExport}>
          Εκτύπωση Διαγράμματος 
        </Button>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : data && data.length > 0 ? (
        <>
          <Box id="chart-container">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time_label"
                  tickFormatter={tickFormatter}
                  label={{
                    value: period === 'daily' ? 'Hour Range' : period === 'monthly' ? 'Day' : period === 'weekly' ? 'Day of Week' : 'Time',
                    position: 'insideBottom',
                    offset: -5,
                  }}
                />
                <YAxis label={{ value: 'Total Successes', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                {targetGroups.map((tg) => (
                  <Bar
                    key={tg}
                    dataKey={`tg_${tg}`}
                    name={`Group ${tg}`}
                    fill={colors[tg] || "#000000"}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Box>
          {/* Display per time label additions */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">
              Σύνολο πονταρίσματος ανά  {period === 'daily' ? 'Hour' : period === 'weekly' ? 'Day' : period === 'monthly' ? 'Day' : 'Date'}:
            </Typography>
            {data.map(item => (
              <Typography key={item.time_label}>
                {item.time_label}: {item.additions}
              </Typography>
            ))}
          </Box>
        </>
      ) : (
        <Typography>Δεν υπάρχουν διαθέσιμα δεδομένα για την επιλεγμένη περίοδο.</Typography>
      )}
    </Box>
  );
};

export default CoefficientsThanOneChart;
