// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6 mt-auto">
      <div className="container mx-auto text-center">
        <p className="text-lg font-semibold">&copy; 2024 BINO. All rights reserved.</p>
        <p className="mt-2">Δημιουργήθηκε από: Δημήτριος Ντάλντος</p>
        <p className="mt-2">
          Για προτάσεις ή προβλήματα, επικοινωνήστε μαζί μας μέσω των παρακάτω εφαρμογών:
        </p>

        <div className="flex justify-center space-x-6 mt-4">
          <a
            href="https://m.me/DimitriosNtaltnos"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-400 transition-colors"
          >
            <i className="fab fa-facebook-messenger text-2xl"></i>
          </a>

          <a
            href="https://wa.me/+306989690247"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-400 transition-colors"
          >
            <i className="fab fa-whatsapp text-2xl"></i>
          </a>

          <a
            href="viber://public?number=+306989690247"
            className="text-purple-500 hover:text-purple-400 transition-colors"
          >
            <i className="fab fa-viber text-2xl"></i>
          </a>

          <a
            href="mailto:support@bino.gr"
            className="text-yellow-500 hover:text-yellow-400 transition-colors"
          >
            <i className="fas fa-envelope text-2xl"></i>
          </a>
        </div>

        <p className="mt-4 text-sm">
          <i>Ευχαριστούμε που χρησιμοποιείτε την πλατφόρμα μας!</i>
        </p>

        <p className="mt-4 text-sm">
          <i>21+ | ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ | ΓΡΑΜΜΗ ΒΟΗΘΕΙΑΣ ΚΕΘΕΑ: 1114 | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ!</i>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
