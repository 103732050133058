import React, { useState } from 'react';
import { filterUsersByTargetGroupAndHits } from '../../services/api';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import { formatToAthensTime } from '../../utils/formatDate';

const FilteredUsers = () => {
  const [targetGroup, setTargetGroup] = useState(null);
  const [matchCount, setMatchCount] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectionType, setSelectionType] = useState(null);

  const handleOpenDialog = (type) => {
    setSelectionType(type);
    setOpenDialog(true);
  };

  const handleSelectValue = (value) => {
    if (selectionType === 'targetGroup') {
      setTargetGroup(value);
    } else if (selectionType === 'matchCount') {
      setMatchCount(value);
    }
    setOpenDialog(false);
  };

  const handleFilter = async () => {
    if (targetGroup === null || matchCount === null) {
      alert('Please select both Target Group and Match Count.');
      return;
    }

    setLoading(true);
    try {
      const data = await filterUsersByTargetGroupAndHits(targetGroup, matchCount);
      if (data.success) {
        setFilteredUsers(data.users);
      } else {
        alert(`Failed to filter users: ${data.error}`);
      }
    } catch (error) {
      console.error('Error filtering users:', error);
      alert('Error filtering users.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Filter Users by Target Group and Match Count
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <Button
          variant="outlined"
          onClick={() => handleOpenDialog('targetGroup')}
          sx={{ flexGrow: 1 }}
        >
          Select Target Group: {targetGroup ?? 'None'}
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleOpenDialog('matchCount')}
          sx={{ flexGrow: 1 }}
        >
          Select Match Count: {matchCount ?? 'None'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          sx={{ minWidth: 150 }}
        >
          Filter
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : filteredUsers.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="filtered users table">
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Fingerprint</TableCell>
                <TableCell>Target Group</TableCell>
                <TableCell>Match Count</TableCell>
                <TableCell>Coefficients</TableCell>
                <TableCell>Coefficients Than One</TableCell>
                <TableCell>Bonus Coefficients</TableCell> {/* New column */}
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.user_id}>
                  <TableCell>{user.user_id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.fingerprint}</TableCell>
                  <TableCell>{user.target_group}</TableCell>
                  <TableCell>{user.match_count}</TableCell>
                  <TableCell>{user.coefficients}</TableCell>
                  <TableCell>{user.coefficients_than_one}</TableCell>
                  <TableCell>{user.bonus_coefficients}</TableCell> {/* New field display */}
                  <TableCell>{formatToAthensTime(user.timestamp)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No users found with the specified criteria.</Typography>
      )}

      {/* Dialog for selecting Target Group or Match Count */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {selectionType === 'targetGroup' ? 'Select Target Group' : 'Select Match Count'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[...Array(12)].map((_, index) => (
              <Grid item xs={3} key={index}>
                <Button
                  variant="outlined"
                  onClick={() => handleSelectValue(index + 1)}
                  fullWidth
                >
                  {index + 1}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FilteredUsers;
