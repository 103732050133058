import React, { useState } from 'react';
import { calculateStrategy, getUserId } from '../services/api';  
import Loader from '../Loader';
import DonationModal from '../components/DonationModal';
import { Helmet } from 'react-helmet';

const Strategy = () => {
  const [strategyType, setStrategyType] = useState('split_bet');
  const [strategyResult, setStrategyResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const userId = getUserId();  

  const handleGenerateStrategy = async () => {
    try {
      setLoading(true);
      const result = await calculateStrategy(userId, strategyType);
      if (result?.success) {
        setStrategyResult(result.strategy_result);   
      } else {
        setError(result?.error || 'Failed to generate strategy predictions.');
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleStrategyChange = (event) => {
    setStrategyType(event.target.value);
    setStrategyResult(null);   
  };

  return (
    <div className="strategy-page p-4">
      <Helmet>
        <title>Στρατηγικές Προβλέψεις - BINO AI</title>
        <meta 
          name="description" 
          content="Εξερευνήστε τις στρατηγικές προβλέψεις του BINO AI για το ΚΙΝΟ. Επιλέξτε μεταξύ Split Bet, Edge Bet, Way Bet, Top/Bottom, και Left/Right για να αυξήσετε τις πιθανότητές σας." 
        />
        <link rel="canonical" href="https://www.bino.gr/strategy" />
      </Helmet>

      <h1 className="text-center text-4xl font-bold text-blue-800 mt-6">
        Στρατηγικές Προβλέψεις
      </h1>
      
      <div className="mt-6 flex justify-center">
        <select
          value={strategyType}
          onChange={handleStrategyChange}
          className="border border-gray-300 rounded-md p-2"
        >
          <option value="split_bet">Split Bet</option>
          <option value="edge_bet">Edge Bet</option>
          <option value="way_bet">Way Bet</option>
          <option value="top_bottom">Top/Bottom</option>
          <option value="left_right">Left/Right</option>
        </select>
      </div>
  
      <div className="mt-4 flex justify-center">
        <button
          onClick={handleGenerateStrategy}
          className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg shadow-md"
        >
          Δημιουργήστε προβλέψεις στρατηγικής
        </button>
      </div>
  
      {loading && <Loader />}
  
      {error && <p className="error-message text-red-600 mt-4">{error}</p>}
  
      {strategyResult && (
        <div className="comparison-result mt-10 p-8 bg-gradient-to-br from-green-100 to-green-300 rounded-lg shadow-xl border border-green-400">
          <h2 className="text-3xl font-semibold text-green-800 mb-6 text-center">
            Πρόβλεψη για Στρατηγική: {strategyType}
          </h2>
          <div className="mt-4">
            {Object.entries(strategyResult).map(([groupName, numbers]) => (
              <div key={groupName} className="mb-6">
                <div className="flex flex-col gap-4 p-4 rounded-lg mb-3 bg-white shadow-md">
                  <div>
                    <span className="text-lg font-bold">{groupName}:</span>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {numbers.map((number, index) => (
                        <span
                          key={`${groupName}-${number}-${index}`}   
                          className="text-xl font-bold mr-2 px-4 py-2 rounded-full bg-gray-200 text-gray-800"
                        >
                          {number}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
  
      {/* Donation Section (Optional) */}
      <div className="donation-section mt-10 text-center">
        <h2 className="text-2xl font-bold text-blue-800 mb-4">Ευχαριστούμε για τη Στήριξή σας!</h2>
        <p className="text-lg text-gray-700 mb-4">
          Εάν απολαμβάνετε τις προβλέψεις μας και θέλετε να υποστηρίξετε το έργο μας, μπορείτε να κάνετε μια δωρεά!
        </p>
        <div className="flex justify-center space-x-4 mt-4">
          <a
            href="https://www.paypal.com/donate/?hosted_button_id=JJD29TK9MLS5W"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-yellow-500 hover:bg-yellow-600 text-white px-6 py-2 rounded-lg shadow-md"
          >
            Κάντε Δωρεά
          </a>
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md"
          >
            Μάθετε Περισσότερα
          </button>
        </div>

        {showModal && <DonationModal onClose={() => setShowModal(false)} />}
      </div>
    </div>
  );
};

export default Strategy;
